@mixin outline($size) {
  z-index: 1000;
  outline: $outline-default-style $size * 2 $outline-default-color !important;
  outline-offset: $outline-default-offset !important;
}

@mixin outline_with_color($size, $color) {
  z-index: 1000;
  outline: $outline-default-style $size * 2 $color !important;
  outline-offset: $outline-default-offset !important;
}
