a,
span,
input {
  &:focus-visible {
    @include outline(2px);
  }
}

input {
  &:hover {
    @include outline_with_color(2px, $blue-light);
  }
}
