.carousel-indicators li,
.carousel-indicators li.active {
  position: relative;
  width: 18px;
  height: 18px;
  border-width: 2px;
  box-shadow: 0 0 0 1px #808080;
}

.carousel-indicators.active li {
  background-color: rgba(100, 149, 253, 0.6);
}

.carousel-indicators.active li.active {
  background-color: white;
}

.carousel-tablist-highlight {
  position: absolute;
  display: block;
  outline: 2px solid transparent;
  background-color: transparent;
  box-shadow: 0 0 0 1px transparent;
}

.carousel-tablist-highlight.focus {
  outline: 2px solid #6495ed;
  background-color: rgba(0, 0, 0, 0.4);
}

a.carousel-control:focus-visible {
  outline: 2px solid #6495ed;
  background-image: linear-gradient(
    to right,
    transparent 0,
    rgba(0, 0, 0, 0.5) 100%
  );
  box-shadow: 0 0 0 1px #000;
}

.carousel-pause-button {
  position: absolute;
  top: -30em;
  left: -300em;
  display: block;
}

.carousel-pause-button.focus {
  top: 0.5em;
  left: 0.5em;
}

.carousel:hover .carousel-caption,
.carousel.contrast .carousel-caption {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
