.btn:focus-visible,
button:focus-visible {
  z-index: 1000;
  outline: solid 4px #ffa00f !important;
  outline-offset: -2px !important;
}

div.active:focus-visible {
  z-index: 1000;
  outline: solid 4px #ffa00f !important;
  outline-offset: -2px !important;
}

a:focus-visible,
span:focus-visible,
input:focus-visible {
  z-index: 1000;
  outline: solid 4px #ffa00f !important;
  outline-offset: -2px !important;
}

input:hover {
  z-index: 1000;
  outline: solid 4px #b3d9ff !important;
  outline-offset: -2px !important;
}

.close:focus-visible {
  z-index: 1000;
  outline: solid 4px #ffa00f !important;
  outline-offset: -2px !important;
}

.nav > li > a:focus-visible {
  z-index: 1000;
  outline: solid 4px #ffa00f !important;
  outline-offset: -2px !important;
}

.carousel-indicators li,
.carousel-indicators li.active {
  position: relative;
  width: 18px;
  height: 18px;
  border-width: 2px;
  -webkit-box-shadow: 0 0 0 1px #808080;
  box-shadow: 0 0 0 1px #808080;
}

.carousel-indicators.active li {
  background-color: rgba(100, 149, 253, 0.6);
}

.carousel-indicators.active li.active {
  background-color: white;
}

.carousel-tablist-highlight {
  position: absolute;
  display: block;
  outline: 2px solid transparent;
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}

.carousel-tablist-highlight.focus {
  outline: 2px solid #6495ed;
  background-color: rgba(0, 0, 0, 0.4);
}

a.carousel-control:focus-visible {
  outline: 2px solid #6495ed;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, transparent), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, transparent 0, rgba(0, 0, 0, 0.5) 100%);
  -webkit-box-shadow: 0 0 0 1px #000;
  box-shadow: 0 0 0 1px #000;
}

.carousel-pause-button {
  position: absolute;
  top: -30em;
  left: -300em;
  display: block;
}

.carousel-pause-button.focus {
  top: 0.5em;
  left: 0.5em;
}

.carousel:hover .carousel-caption,
.carousel.contrast .carousel-caption {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.alert-success {
  color: #2d4821;
}

.alert-info {
  color: #214c62;
}

.alert-warning {
  color: #6c4a00;
  background-color: #f9f1c6;
}

.alert-danger {
  color: #d2322d;
}

.alert-danger:hover {
  color: #a82824;
}
/*# sourceMappingURL=bootstrap-accessibility.css.map */
